<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import TheFormWrapper from "@main/packages/TheFormWrapper";
    import TheModalWrap from "@main/packages/TheModalWrap";

    export default {
        name: "ModalCallback",
        components: { TheModalWrap, TheFormWrapper },
        data() {
            return {
                errorsList: {
                    name: "",
                    phone: "",
                },
                isLoading: false,
                successText: "",
            };
        },
        methods: {
            send(dataFrom) {
                this.isLoading = true;

                this.$store.rootApi
                    .requestCallback({ name: dataFrom.name, phone: dataFrom.phone })
                    .then((response) => {
                        this.isLoading = false;
                        if (response.success) {
                            this.successText =
                                "Спасибо! Мы&nbsp;постараемся позвонить в&nbsp;ближайшие 10&nbsp;минут. Наши операторы работают с&nbsp;9:00 до&nbsp;21:00 по&nbsp;московскому времени";
                            sendAnalytics.send(sendAnalytics.events.call);
                        } else {
                            if (Object.keys(response.errors).length > 0) {
                                Object.keys(response.errors).forEach((error) => {
                                    this.errorsList[error] = response.errors[error];
                                });
                            }
                        }
                    });
            },
        },
    };
</script>

<template>
    <the-modal-wrap title="Обратный звонок">
        <the-form-wrapper
            title="Хотите сделать заказ или возникли вопросы? Мы перезвоним вам в течение 30 минут в рабочее время"
            name-button="Жду звонка"
            :exclude-input="['email']"
            :is-loading="isLoading"
            :resulting-errors-list="errorsList"
            @submit-form="send"
            :success-text="successText"
        >
        </the-form-wrapper>
    </the-modal-wrap>
</template>

<style lang="scss"></style>
